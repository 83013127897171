import React, { useEffect, useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { ReactComponent as Logo } from '@images/elate-logo-horizontal-white.svg'
import { ReactComponent as RiseLogo } from '@images/RISE-Logo-Grey.svg'
import Countdown from '@components/Countdown'
import ThankYouContent from '@components/ThankYouContent'
import FloatingProducts from '@components/FloatingProducts'
import { TimelineMax } from 'gsap'
import * as S from '@styles/components/Hero'
import { Button } from '@styles/components/Button'

export const useHeroData = () => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query HeroContent {
        markdownRemark(fileAbsolutePath: { regex: "/components/hero/" }) {
          frontmatter {
            countdownDate
            countdownLabel
            shadow {
              childImageSharp {
                fluid(maxWidth: 1440) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            product1 {
              childImageSharp {
                fluid(maxWidth: 194) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            product2 {
              childImageSharp {
                fluid(maxWidth: 292) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            product3 {
              childImageSharp {
                fluid(maxWidth: 291) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            product4 {
              childImageSharp {
                fluid(maxWidth: 156) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            product5 {
              childImageSharp {
                fluid(maxWidth: 171) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            product6 {
              childImageSharp {
                fluid(maxWidth: 210) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            product7 {
              childImageSharp {
                fluid(maxWidth: 129) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    `
  )
  return markdownRemark.frontmatter
}

const HeroTimeline = ({ current: target }) => {
  return new TimelineMax({ paused: true })
    .staggerFromTo(
      target.querySelectorAll('.rise-logo g path'),
      1,
      {
        autoAlpha: 0,
        y: '50%',
      },
      {
        autoAlpha: 1,
        y: '0%',
      },
      0.125
    )
    .fromTo(
      target.querySelectorAll('.rise-logo > path'),
      1,
      {
        autoAlpha: 0,
        y: '50%',
      },
      {
        autoAlpha: 1,
        y: '0%',
      }
    )
}

const HeroContent = ({
  isLanding,
  hasCountdown,
  countdownDate,
  countdownLabel,
}) => {
  if (isLanding && hasCountdown) {
    return (
      <Countdown
        countdownDate={countdownDate}
        countdownLabel={countdownLabel}
      />
    )
  } else if (!isLanding) {
    return <ThankYouContent />
  }

  return null
}

const Hero = ({ countdownDate, hasCountdown, isLanding }) => {
  const ref = useRef(null)
  const {
    countdownLabel,
    product1,
    product2,
    product3,
    product4,
    product5,
    product6,
    product7,
    shadow,
  } = useHeroData()

  const floatingProps = {
    shadow,
    products: {
      product1,
      product2,
      product3,
      product4,
      product5,
      product6,
      product7,
    },
  }

  // Component Did Mount
  useEffect(() => {
    const timeline = HeroTimeline(ref)
    timeline.play().delay(1)
    // Component Did UnMount
    return () => {
      timeline.kill()
    }
  }, [])

  return (
    <S.Hero className="hero" ref={ref} hasCountdown={hasCountdown}>
      <FloatingProducts {...floatingProps} />
      <div className="container">
        <header className="header">
          <a href="https://www.elatecosmetics.ca">
            <Logo className="logo" alt="Elate Logo" />
          </a>
          {!hasCountdown && (
            <Button
              className="button"
              href="https://www.elatecosmetics.ca/collections/rise-collection"
              styleType="ghost"
            >
              Shop Now
            </Button>
          )}
        </header>
        <div className="inner">
          <RiseLogo className="rise-logo" alt="rise logo" />
          <HeroContent
            countdownDate={countdownDate}
            countdownLabel={countdownLabel}
            isLanding={isLanding}
            hasCountdown={hasCountdown}
          />
        </div>
      </div>
    </S.Hero>
  )
}
export default Hero
