import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import * as S from '@styles/components/Tiles'
import Magic from '@components/Magic'
import { Button } from '@styles/components/Button'

export const useTilesData = () => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query TilesContent {
        markdownRemark(fileAbsolutePath: { regex: "/components/tiles/" }) {
          frontmatter {
            heading
            tileImage {
              childImageSharp {
                fluid(maxWidth: 1500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          html
        }
      }
    `
  )
  return markdownRemark
}

const Tiles = ({ hasCountdown }) => {
  const {
    frontmatter: { tileImage },
    html,
  } = useTilesData()

  return (
    <S.Tiles className="tiles">
      <div className="inner">
        <div className="content">
          <article className="content-inner">
            <Magic ignoreWrapper>
              <div dangerouslySetInnerHTML={{ __html: html }} />
            </Magic>
            {!hasCountdown && (
              <Magic>
                <Button
                  className="button"
                  href="https://www.elatecosmetics.ca/collections/rise-collection"
                  styleType="filled"
                >
                  Shop Now
                </Button>
              </Magic>
            )}
          </article>
        </div>

        <div className="image">
          <Image
            {...tileImage.childImageSharp}
            alt="Elate Rise Product Collection"
          />
        </div>
      </div>
    </S.Tiles>
  )
}

export default Tiles
